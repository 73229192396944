// @flow

import { createSlice } from "@reduxjs/toolkit";

import {
  classificationLegend,
  classificationLegend2024,
  changeLegend,
  sandBarLegend,
} from "../config";

const initialState = {
  classification: {
    "fill-opacity": 1,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "gridcode"]],
      ...[].concat(
        ...classificationLegend.map((item) => [item.value, item.color])
      ),
    ],
    "fill-outline-color": "rgba(0,0,0,0)",
  },
  classification2024: {
    "fill-opacity": 1,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "gridcode"]],
      ...[].concat(
        ...classificationLegend2024.map((item) => [item.value, item.color])
      ),
    ],
    "fill-outline-color": "rgba(0,0,0,0)",
  },
  change: {
    "fill-opacity": 1,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "change"]],
      ...[].concat(...changeLegend.map((item) => [item.value, item.color])),
    ],
    "fill-outline-color": "rgba(0,0,0,0)",
  },
  frt: {
    "line-color": "#0172FF",
  },
  "sand-bar": {
    "fill-opacity": 1,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["to-number", ["get", "gridcode"]],
      ...[].concat(...sandBarLegend.map((item) => [item.value, item.color])),
    ],
    "fill-outline-color": "rgba(0,0,0,0)",
  },
};

function setPaint(state, action) {
  const { id, paint } = action;

  state[id] = paint;
}

const paintDuck = createSlice({
  name: "paint",
  initialState,
  reducers: {
    setPaint,
  },
});

export default paintDuck;
